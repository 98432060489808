<template>
    <div class="container-fluid pl-5 pr-0">
        <div class="d-flex justify-content-end">
            <panel :steps="steps" class="bg-gray-300">
                <template #header>
                    <div class="d-flex justify-content-between">
                        <h4 class="mb-0">Info</h4>
                        <button class="close">×</button>
                    </div>
                </template>

                <div class="font-weight-medium font-20">
                    Profile
                </div>
                <div class="text-secondary mt-4">
                    <p>
                        Your profile relates to the information about yourself….
                    </p>
                    <p>
                        consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>
                    <p>
                        <span class="font-weight-medium text-dark">Title</span>
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>
                </div>
            </panel>

            <panel class="bg-gray-200">
                <template #header>
                    <div class="d-flex justify-content-between">
                        <h4 class="mb-0">New Client Order</h4>
                        <div class="text-primary">
                            <fa class="mr-3" icon="info-circle" size="lg"></fa>
                            <fa icon="tasks" size="lg"></fa>
                        </div>
                    </div>
                </template>

                <div class="font-weight-medium font-20">
                    Job Details
                </div>
                <div class="mt-4">
                    <p class="text-secondary ">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>

                    <div class="font-weight-medium font-18 mb-2">Service Type</div>

                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1">
                        <label class="form-check-label" for="exampleRadios1">
                            <div class="font-14">Transcription</div>
                            <small class="text-secondary">Lorem ipsum dolor sit amet,</small>
                        </label>
                    </div>
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1" checked>
                        <label class="form-check-label" for="exampleRadios2">
                            <div class="font-14">Captioning</div>
                            <small class="text-secondary">Lorem ipsum dolor sit amet,</small>
                        </label>
                    </div>

                    <div class="font-weight-medium font-18 mt-4 mb-2">Add ons</div>

                    <div class="form-check mb-2">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked>
                        <label class="form-check-label" for="defaultCheck1">
                            <div class="font-14">Timecoding</div>
                            <small class="text-secondary">(+25% to current rate)</small>
                        </label>
                    </div>

                    <div class="form-check mb-2">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                        <label class="form-check-label" for="defaultCheck2">
                            <div class="font-14">Full Verbatim</div>
                            <small class="text-secondary">(+25% to current rate)</small>
                        </label>
                    </div>

                    <div class="btn btn-block btn-secondary disabled my-3 bg-gray-500 border-0" >Button</div>

                    <div class="font-weight-medium font-18 mb-2">Turnaround Package</div>

                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios3" value="option1">
                        <label class="form-check-label" for="exampleRadios3">
                            <div class="font-14">Select Package</div>
                        </label>
                    </div>

                    <div class="btn btn-block btn-primary my-3" >Button</div>

                </div>
            </panel>

            <panel class="bg-gray-100" scrollable>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <h4 class="mb-0">Packages</h4>
                        <button class="close">×</button>
                    </div>
                </template>

                <div class="font-weight-medium font-20">
                    Select Package
                </div>
                <div class="mt-4">

                    <p class="text-secondary ">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                    </p>

                    <div class="mt-4">

                        <div class="card shadow-sm mb-3">
                            <div class="card-body">
                                <fa class="text-primary" icon="book" size="2x"></fa>
                                <table class="mt-3 w-100">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <h4>3 Days</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4 class="text-primary">US$ 338</h4>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="text-secondary font-14">Turnaround Time</div>
                                        </td>
                                        <td class="text-right">
                                            <div class="text-secondary font-14">US$3 Per Minute</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="text-secondary font-14">Est. delivery date</div>
                                        </td>
                                        <td class="text-right">
                                            <div class="text-secondary font-14">14 March 2021</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <button class="btn btn-primary btn-block mt-4">Button</button>
                            </div>
                        </div>

                        <div class="card shadow-sm mb-3">
                            <div class="card-body">
                                <fa class="text-primary" icon="book" size="2x"></fa>
                                <table class="mt-3 w-100">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <h4>Express</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4 class="text-primary">US$ 4450</h4>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="text-secondary font-14">Turnaround Time</div>
                                        </td>
                                        <td class="text-right">
                                            <div class="text-secondary font-14">US$4 Per Minute</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <button class="btn btn-primary btn-block mt-4">Button</button>
                            </div>
                        </div>

                        <div class="card shadow-sm mb-3">
                            <div class="card-body">
                                <fa class="text-primary" icon="book" size="2x"></fa>
                                <table class="mt-3 w-100">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <h4>1 Day</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4 class="text-primary">US$ 445</h4>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="text-secondary font-14">Turnaround Time</div>
                                        </td>
                                        <td class="text-right">
                                            <div class="text-secondary font-14">US$4 Per Minute</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <button class="btn btn-primary btn-block mt-4">Button</button>
                            </div>
                        </div>

                        <div class="card shadow-sm mb-3">
                            <div class="card-body">
                                <fa class="text-light-green" icon="book" size="2x"></fa>
                                <table class="mt-3 w-100">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <h4>2 Days</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4 class="text-light-green">US$ 3</h4>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="text-secondary font-14">Turnaround Time</div>
                                        </td>
                                        <td class="text-right">
                                            <div class="text-secondary font-14">Per Minute</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <button class="btn btn-primary btn-block mt-4">Button</button>
                            </div>
                        </div>
                    </div>

                </div>
            </panel>
        </div>

    </div>
</template>

<script>
import Panel from "../../../src/components/Panel";

export default {
    name: 'Panels',
    components: {
        Panel
    },
    data() {
        return {
            steps: [
                {
                    title: "Upload Files",
                    done: true,
                    active: false
                },
                {
                    title: "Select Services",
                    done: false,
                    active: true
                },
                {
                    title: "Supporting Information",
                    done: false,
                    active: false
                },
                {
                    title: "Payment Details",
                    done: false,
                    active: false
                },
                {
                    title: "Payment (paypal)",
                    done: false,
                    active: false
                }
            ]
        }
    }

}
</script>

<style scoped>
    .panel::v-deep {
        height: calc( 100vh - 92px);
    }
</style>
